<template>
    <div class="main-container">
      <h5 class="pageTitle" v-html="pageTitle"></h5>
      <mdb-container class="search-container">
        <mdb-row>
          <div class="ml-3">
            <mdb-select
                class="search-select"
                outline
                resetBtn
                v-model="selectOptions"
                placeholder="검색 항목"
                @getValue="getSelectValue"/>
          </div>
          <mdb-col>
            <mdb-input type="text" @input="handleSearchLog" v-model="search" outline placeholder="검색어를 입력하세요."></mdb-input>
          </mdb-col>
        </mdb-row>
      </mdb-container>

      <mdb-container>
        <div class="outer-wrapper">
            <div class="title">
                <h5>오늘 출석한 인원</h5>
            </div>

            <div class="wrapper" id="attendance-list"></div>
        </div>
      </mdb-container>

      <mdb-container>
        <mdb-datatable-2
            v-model="data"
            class="text-center"
            striped
            bordered
            hover
            :searching="{value: search, field: fieldName} "            
            noFoundMessage="데이터가 없습니다."/>
      </mdb-container>
    </div>
  </template>

    
<style scoped>
.main-container {
  padding: 15px;
}

.pageTitle {
  font-weight: bold;
}

.search-select {
  width: 150px;
}

/deep/ .btn-detail {
border: 1px solid #2e56f4;
background: #2e56f4;
border-radius: 4px;
font-weight: 500;
font-size: 16px;
color: #FFFFFF;
padding: 0 16px;  
}

.outer-wrapper {
  background-color: #f9f9f9;
  padding: 20px 20px 10px 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 10px;
}
.title {
  display:flex;
  align-items: center;
  margin-bottom: 10px;
}
.wrapper {
  display: flex;    
  padding: 10px;
  flex-wrap: wrap;    
}
.refresh-button {
  cursor: pointer;
  background-color: #ff8c00;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 9px;
  margin-left: 10px;
  margin-top: -7px;
}

</style>

  
<script>
  import {mdbCol, mdbContainer, mdbDatatable2, mdbInput, mdbRow, mdbSelect} from "mdbvue";
  import user_list from "@/assets/data/columns/user_list";
  import {sendWorkLog} from "@/lib/logModule";
  import axios from "@/axios";
  import '@/assets/css/attendance.css'
  
  export default {
    name: "NonUserList",
    components: {
      mdbContainer,
      mdbDatatable2,
      mdbRow,
      mdbCol,
      mdbInput,
      mdbSelect,
    },
    data() {
      return {
        token: this.$store.state.token.access_token,
        pageTitle: '출석 관리',
        data: {
          columns: user_list,
          rows: []
        },
        search: '',
        fieldName: '',
        selectOptions: [
          {text: "이름", value: "name"},
          {text: "전화번호", value: "phone"},
        ],
        userType: 'approved'
      }
    },
    created() {
        window.updateAttendance = this.updateAttendance;
    },
    mounted() {
      this.init();
    },    
    methods: {
      init() {
        this.getUsers();
        this.getTodayUsers();
      },
      getUsers() {
        const self = this;
        self.data.rows.splice(0);
        let orderNo = 0;
  
        const data = {
          "status": self.userType,
        }
  
        const config = {
          method: 'get',
          url: '/users',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`
          },
          params: data
        };
            
        axios(config)
            .then((querySnapshot) => {
              if (querySnapshot.data.size === 0) {
                return
              }
              querySnapshot.data.forEach((doc) => {
                orderNo++;
                doc['orderNo'] = orderNo;
                doc['action'] = '<button class="btn-detail" onclick="updateAttendance(\'' + doc.uuid + '\'' + ', true)">출석</button>';
                self.data.rows.push(doc)
              })
              sendWorkLog('050600');
            })
      },
      updateAttendance(uuid, isAttendance) {
        const config = {
            method: 'put',
            url: `/users/attendance?id=${uuid}&attendance=${isAttendance}`,
            headers: {
                'Content-Type': 'application/json'                
            }
        };

        axios(config)
            .then((res) => {                
                console.log('attendance res : ' + JSON.stringify(res.data));
                this.getTodayUsers();
                if(isAttendance){
                  sendWorkLog('050800');
                }
                
            })
      },
      getTodayUsers() {
        const config = {
            method: 'get',
            url: '/users/today',
            headers: {
                'Content-Type': 'application/json'                
            }
        };

        axios(config)
            .then((res) => {                
                res.data.forEach(item => {
                    this.addAttendance(item.name, item.uuid);
                });
            })
      },
      handleSearchLog() {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        if (this.search && this.search.length > 1) {
          sendWorkLog('050700');  // 회원 검색
          //console.log('회원 검색 로그 전송됨:', this.search);
        }
      }, 1000); // 1초 동안 입력 없으면 검색 시도로 간주
    },
      getSelectValue(value) {
        const self = this;
        self.fieldName = value;
      },
      hideContainer() {
        var container = document.querySelector('.container');
        container.style.display = 'none';
      },
      addAttendance(name, userId) {
        if (document.getElementById(userId)) {            
            return;
        }

        // 새로운 아이템을 생성합니다.
        var newItem = document.createElement("div");
        newItem.classList.add("name-container");
        newItem.id = userId;

        // 이름을 생성하고 추가합니다.
        var newName = document.createElement("span");
        newName.classList.add("name");
        newName.textContent = name;

        // X 버튼을 생성하고 추가합니다.
        var closeButton = document.createElement("button");
        closeButton.classList.add("close-button");
        closeButton.textContent = "X";
        closeButton.addEventListener('click', () => this.removeAttendance(userId));

        // 생성한 요소들을 아이템에 추가합니다.
        newItem.appendChild(newName);
        newItem.appendChild(closeButton);

        // 아이템을 목록에 추가합니다.
        var attendanceList = document.getElementById("attendance-list");
        attendanceList.appendChild(newItem);
      },
      removeAttendance(id) {
        var itemToRemove = document.getElementById(id);
        if (itemToRemove) {
            itemToRemove.remove();
            this.updateAttendance(id, false);
            sendWorkLog('050601');
        }
      }      
    }
  }
  </script>
